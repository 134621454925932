import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDKOZnogCC_0RywExjVwjlz3uYEjC1UXGc",
  authDomain: "mug-city.firebaseapp.com",
  projectId: "mug-city",
  storageBucket: "mug-city.appspot.com",
  messagingSenderId: "679906329945",
  appId: "1:679906329945:web:b3ea2e92d540a464e4b848",
  measurementId: "G-T9K9TNGJW5",
};
// Init firebase
initializeApp(firebaseConfig);

// init services
const auth = getAuth();
const firestore = getFirestore();
const functions = getFunctions();
const analytics = getAnalytics();

export { auth, firestore, functions, analytics };
