import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogout } from "../../hooks/useLogout";
import "./TopBar.css";

export default function TopBar() {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  return (
    <div className="top-bar">
      <ul>
        <li className="logo">
          <span>Mug.City</span>
        </li>
        {!user && <></>}
        {user && (
          <li>
            <button className="btn inverted" onClick={logout}>
              Logout
            </button>
          </li>
        )}
      </ul>
    </div>
  );
}
