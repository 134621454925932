import TargetLink from "./TargetLink";
import { TargetDoc } from "./Targets";
import TimeAgo from "react-timeago";
import { useState } from "react";
import { HttpsCallable, httpsCallable } from "firebase/functions";
import { functions } from "../../firebase/config";

const hour = 3600000; // 1000 * 60 * 60 *12

export default function TargetCard(props: {
  index: number;
  target: TargetDoc;
  apiKey: string;
}) {
  const { index, target, apiKey } = props;
  const [wasClicked, setWasClicked] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Calculations
  const score = (target.score / 10000).toFixed(0);
  const average = Math.floor(target.moneyTotal / target.count);
  const lastHitMilliS = target.lastHit * 1000;
  const lastMug = new Date(lastHitMilliS);
  const timeDiff = Date.now() - lastHitMilliS;
  const profile = target.profile;
  const stats = profile?.personalstats ?? null;
  const jobLong =
    (profile?.basicicons.icon73 || profile?.basicicons.icon27) ?? "";
  const jobRegEx = /\(([A-Za-z ]+)\)/;
  const jobArray = jobRegEx.exec(jobLong);
  const name = target.profile ? " " + target.profile.name + " " : " ";

  const handleDelete = async () => {
    console.log(apiKey);

    if (typeof apiKey !== "string") return;
    if (apiKey === "") return;

    setIsDeleting(true);

    const deleteTarget = httpsCallable(
      functions,
      "deleteTarget"
    ) as HttpsCallable<{ tornApiKey: string; targetId: string }, unknown>;
    console.log(
      await deleteTarget({ tornApiKey: apiKey, targetId: target.id })
    );
  };

  return (
    <div
      className={
        "target" +
        (wasClicked ? " was-clicked" : "") +
        (isDeleting ? " skeleton" : "")
      }
    >
      <div className="row">
        <div>{`${index + 1}.${name}[${target.id}]`}</div>
        <div className="spacer"></div>
        <div
          className={
            "icon-button" +
            (confirmDelete ? " confirm-delete" : "") +
            (isDeleting ? " skeleton" : "")
          }
          onClick={() => {
            setConfirmDelete(!confirmDelete);
          }}
        >
          <span className="material-symbols-rounded">delete</span>
        </div>
      </div>

      {profile && profile.basicicons.icon27 && jobArray && (
        <small>💼 {jobArray[1]}</small>
      )}
      {profile && profile.basicicons.icon73 && jobArray && (
        <small>🏢 {jobArray[1]} (Director)</small>
      )}
      {profile && !jobArray && <small>[No job]</small>}

      {profile && stats && (
        <div className="target-profile">
          <small>
            <p>
              Level {profile.level} - Age: {profile.age.toLocaleString()} -{" "}
              {stats.xantaken.toLocaleString()} Xanax
            </p>
            {/* <p>
              Elo: {stats.elo} - Defend Success:{" "}
              {((stats.defendswon / stats.defendslost) * 100).toPrecision(3)}%
            </p> */}
          </small>
        </div>
      )}
      <p>score: {score.toLocaleString()}</p>
      <p>previous: ${target.moneyPrevious.toLocaleString()}</p>

      <small>
        {target.count > 1 && (
          <>
            <p>average: ${average.toLocaleString()}</p>
            <p>total hits: {target.count}</p>
          </>
        )}

        <p
          className={
            timeDiff < 12 * hour
              ? "recent"
              : timeDiff < 36 * hour
              ? "optimal-time"
              : ""
          }
        >
          Last Hit: <TimeAgo date={lastMug} />
        </p>

        {target.note && <p>📝 {target.note}</p>}

        {confirmDelete && (
          <div className="row">
            <div
              className="text-button"
              style={{
                color: isDeleting
                  ? "var(--disabled-color)"
                  : "var(--urgent-color)",
              }}
              onClick={handleDelete}
            >
              <span>Delete</span>
            </div>
            <div className="spacer"></div>
            <div
              className="text-button"
              style={{
                color: isDeleting
                  ? "var(--disabled-color)"
                  : "var(--primary-color)",
              }}
              onClick={() => setConfirmDelete(false)}
            >
              <span>Cancel</span>
            </div>
            <div className="spacer"></div>
          </div>
        )}
        {!confirmDelete && (
          <div className="links">
            <TargetLink
              text="Profile"
              target={target}
              url={"https://www.torn.com/profiles.php?XID=" + target.id}
              setWasClicked={setWasClicked}
            />{" "}
            <TargetLink
              text="Attack"
              target={target}
              url={
                "https://www.torn.com/loader.php?sid=attack&user2ID=" +
                target.id
              }
              setWasClicked={setWasClicked}
            />
          </div>
        )}
      </small>
    </div>
  );
}
