import { createContext, useReducer, useEffect } from "react";
import { auth } from "../firebase/config";
import { onAuthStateChanged, User } from "firebase/auth";

type State = {
  user: null | User;
  authIsReady: boolean;
};

type ActionType = "LOGIN" | "LOGOUT" | "AUTH_IS_READY";

type Action = {
  type: ActionType;
  payload: User | null;
};

interface Value extends State {
  dispatch: React.Dispatch<Action>;
}

export const AuthContext = createContext(null as Value | null);

export const authReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...state, user: null };
    case "AUTH_IS_READY":
      return { user: action.payload, authIsReady: true };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      dispatch({ type: "AUTH_IS_READY", payload: user });
      unsubscribe();
    });
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch } as Value}>
      {children}
    </AuthContext.Provider>
  );
};
