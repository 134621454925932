// firebase imports
import { signOut } from "firebase/auth";
import { auth } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";
import { usePersistentStorageValue } from "./usePersistentStorageValue";

export function useLogout() {
  const { dispatch } = useAuthContext();
  const [, setApiKey] = usePersistentStorageValue("tornApiKey");

  function logout() {
    setApiKey(undefined);
    signOut(auth)
      .then(() => {
        dispatch({ type: "LOGOUT", payload: null });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  return { logout };
}
