import { doc, onSnapshot } from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { firestore } from "../firebase/config";
import { useAuthContext } from "../hooks/useAuthContext";
import { UserData } from "../types/UserData";

export const UserDataContext = createContext({
  userData: undefined as UserData | undefined,
  error: null as null | string,
});

export const UserDataContextProvider = ({ children }: { children: any }) => {
  const { user } = useAuthContext();
  const [userData, setBasic] = useState(undefined as UserData | undefined);
  const [error, setError] = useState(null as null | string);

  useEffect(() => {
    if (!user) {
      setBasic(undefined);
      return;
    }

    const userDataRef = doc(firestore, `users/${user.uid}`);

    const unsubscribe = onSnapshot(
      userDataRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setBasic(snapshot.data() as UserData);
          setError(null);
        } else {
          setError("Could not retrieve player data");
        }
      },
      (error) => {
        console.error(error.message);
        setError("Failed to get player data");
      }
    );

    return () => unsubscribe();
  }, [user]);

  return (
    <UserDataContext.Provider value={{ userData, error }}>
      {children}
    </UserDataContext.Provider>
  );
};
