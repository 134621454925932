import { useState } from "react";
import { useAuthContext } from "./useAuthContext";

// Firebase imports
import { auth, functions } from "../firebase/config";
import { signInWithCustomToken } from "firebase/auth";
import { HttpsCallable, httpsCallable } from "firebase/functions";
import { usePersistentStorageValue } from "./usePersistentStorageValue";

export function useLogin() {
  const [error, setError] = useState(null as string | null);
  const { dispatch } = useAuthContext();
  const [, setApiKey] = usePersistentStorageValue("tornApiKey");

  async function login(tornApiKey: string) {
    setError(null);

    const signin = httpsCallable(functions, "signin") as HttpsCallable<
      { tornApiKey: string },
      { token?: string; error?: string }
    >;
    setApiKey(undefined);
    const response = await signin({ tornApiKey: tornApiKey });
    setApiKey(tornApiKey);
    const { error, token } = response.data;
    if (error) {
      console.log(error);
      setError(error);
      return;
    }

    if (!token) {
      console.log(error);
      setError("Failed to login. Please check your API Key and try again.");
      return;
    }

    signInWithCustomToken(auth, token)
      .then((res) => {
        dispatch({ type: "LOGIN", payload: res.user });
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  return { error, login };
}
