import { HttpsCallable, httpsCallable } from "firebase/functions";
import { useEffect } from "react";
import "./App.css";
import TopBar from "./components/top-bar/TopBar";
import { functions } from "./firebase/config";
import { useAuthContext } from "./hooks/useAuthContext";
import { usePersistentStorageValue } from "./hooks/usePersistentStorageValue";
import Login from "./pages/login/Login";
import Targets from "./pages/targets/Targets";
import { UserDataContextProvider } from "./context/UserDataContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Transfer from "./pages/transfer/Transfer";

function App() {
  const { user, authIsReady } = useAuthContext();
  const [apiKey] = usePersistentStorageValue("tornApiKey", "");

  // Can use this to auto update targets
  useEffect(() => {
    if (authIsReady && user) {
      if (typeof apiKey !== "string") return;
      if (apiKey === "") return;

      const update = httpsCallable(functions, "update") as HttpsCallable<
        { tornApiKey: string },
        unknown
      >;
      update({ tornApiKey: apiKey });
    }
  }, [user]);

  return (
    <div className="App">
      {authIsReady && (
        <UserDataContextProvider>
          <BrowserRouter>
            <div className="container">
              <TopBar />
              <div className="page-content">
                <Routes>
                  <Route
                    path="/transfer"
                    element={user ? <Transfer /> : <Navigate to="/" />}
                  />
                  <Route path="*" element={user ? <Targets /> : <Login />} />
                </Routes>
              </div>
            </div>
          </BrowserRouter>
        </UserDataContextProvider>
      )}
    </div>
  );
}

export default App;
