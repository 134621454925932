import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions } from "../../firebase/config";

export default function Transfer() {
  const [error, setError] = useState(null as string | null);
  const [recipient, setRecipient] = useState("");
  const [isPending, setIsPending] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numRegEx = /[^0-9]*$/;
    const newVal = e.target.value.replace(numRegEx, "");
    setRecipient(newVal);
  };

  const handleClick = () => {
    setError(null);
    setIsPending(true);

    const transferTargets = httpsCallable<{ recipient: string }, any>(
      functions,
      "transferTargets"
    );
    transferTargets({ recipient: recipient })
      .then((response) => {
        setIsPending(false);
        if (response.data.error) {
          setError(response.data.error);
        }
      })
      .catch((error) => {
        console.log(error);

        if (error instanceof Error) {
          setError(error.message);
        }
      });
  };

  return (
    <div>
      <h1>Transfer Targets</h1>
      <p>
        Welcome to a hidden page! This page allows you to transfer your targets
        to another player. This will{" "}
        <strong>
          delete ALL of your target data and send it to someone else.
        </strong>{" "}
        This is only for people who mug as a team, and works best if one player
        maintains the list.
      </p>
      <p className="warning">Be careful. This action is not reversible.</p>
      <br />
      <br />
      {error && <p className="error">{error}</p>}
      <label>
        <span>Recipient's Torn ID</span>
        <input
          type="text"
          placeholder=""
          value={recipient}
          onChange={handleChange}
        />
        <button className="btn" onClick={handleClick} disabled={isPending}>
          Transfer
        </button>
      </label>
    </div>
  );
}
