export default function SortButton(props: {
  text: string;
  sort: string;
  setSort: (sort: string) => void;
}) {
  const { text, sort, setSort } = props;
  const value = text.toLowerCase();

  const handleClick = () => {
    // console.log(value);
    setSort(value);
  };

  return (
    <span
      className={"sort-button" + (sort === value ? " active" : "")}
      id={value}
      onClick={handleClick}
    >
      {text}
    </span>
  );
}
