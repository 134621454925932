import { User } from "firebase/auth";
import { limit, orderBy } from "firebase/firestore";
import { HttpsCallable, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { functions } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollection } from "../../hooks/useCollection";
import { useLogout } from "../../hooks/useLogout";
import { usePersistentStorageValue } from "../../hooks/usePersistentStorageValue";
import { useUserDataContext } from "../../hooks/useUserDataContext";
import { TargetData } from "../../types/TargetData";
import SortButton from "./SortButton";
import TargetCard from "./TargetCard";

import "./Targets.css";

export interface TargetDoc extends TargetData {
  id: string;
}

export default function Targets() {
  const { user } = useAuthContext() as { user: User };
  const { documents: targetDocs, error: targetError } = useCollection(
    `users/${user.uid}/targets`,
    [orderBy("score", "desc"), limit(60)]
  ) as { documents: TargetDoc[] | null; error: string | null };
  const [apiKey] = usePersistentStorageValue("tornApiKey", "");
  const [isPending, setIsPending] = useState(false);
  const [sort, setSort] = useState("score");
  const { logout } = useLogout();
  const { userData } = useUserDataContext();
  const [legacyPending, setLegacyPending] = useState(false);

  useEffect(() => {
    if (userData) {
      const { hasLegacy, gotLegacy } = userData;
      if (hasLegacy && !gotLegacy && !legacyPending) {
        setLegacyPending(true);
        const getLegacyTargets = httpsCallable(functions, "getLegacy");
        getLegacyTargets().finally(() => {
          setLegacyPending(false);
        });
      }
    }
  }, [userData]);

  const handleClick = async () => {
    if (apiKey === "") return;

    setIsPending(true);
    const update = httpsCallable(functions, "update") as HttpsCallable<
      { tornApiKey: string },
      unknown
    >;
    try {
      const response: { data?: any } = await update({
        tornApiKey: apiKey,
      });
      if (response.data?.error === "Incorrect key") {
        logout();
      }
    } catch (error) {}
    setIsPending(false);
  };

  return (
    <>
      <div className="right">
        <button
          className="btn secondary right-margin"
          onClick={handleClick}
          disabled={isPending}
        >
          Update
        </button>
        <br />
        <br />
      </div>
      {targetDocs && targetDocs.length === 0 && (
        <p className="warning">
          You have no targets yet. Press the "Update" button in the top-right
          corner to load targets.
        </p>
      )}
      <div className="center">
        Sort: <SortButton text="Score" sort={sort} setSort={setSort} />{" "}
        <SortButton text="Previous" sort={sort} setSort={setSort} />{" "}
        <SortButton text="Average" sort={sort} setSort={setSort} />
      </div>
      {targetError && <p className="error">Failed to connect to database.</p>}
      {targetDocs && targetDocs.length > 0 && (
        <ul>
          {targetDocs
            .sort((a, b) => {
              switch (sort) {
                case "previous":
                  return b.moneyPrevious - a.moneyPrevious;

                case "average":
                  const aAverage = a.moneyTotal / a.count;
                  const bAverage = b.moneyTotal / b.count;
                  return bAverage - aAverage;

                default:
                  return b.score - a.score;
              }
            })
            .map((target, index) => {
              return (
                <li key={target.id}>
                  <TargetCard index={index} target={target} apiKey={apiKey} />
                </li>
              );
            })}
        </ul>
      )}
    </>
  );
}
