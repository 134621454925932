import { useState } from "react";
import { useLogin } from "../../hooks/useLogin";
import { usePersistentStorageValue } from "../../hooks/usePersistentStorageValue";

import "./Login.css";

export default function Login() {
  const { login, error: loginError } = useLogin();
  const [apiKey, setApiKey] = usePersistentStorageValue(
    "tornApiKey",
    null as string | null
  );
  const [inputApi, setInputApi] = useState(apiKey ?? "");
  const [isPending, setIsPending] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPending(true);
    // setApiKey(inputApi);
    await login(inputApi);
    setIsPending(false);
  };

  return (
    <div className="center">
      <h1>Welcome to Mug.City</h1>
      <br />

      <small>
        Need an API Key?{" "}
        <a
          href="https://www.torn.com/preferences.php#tab=api?step=addNewKey&title=Mug.City&user=timestamp,profile,personalstats,log"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click Here
        </a>
      </small>
      <br />
      <br />
      <div className="description">
        <p>
          <small>
            This site helps you keep track of the amounts you've mugged from
            people. Enter your API key, and your previous mugs will be loaded
            showing previous amounts, averages, and other useful information.
            Mug.City must use your log to get the mug amounts, so an API key
            with log access is required.
          </small>
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <label>
          <span>Torn API Key</span>
          {loginError && <p className="error">{loginError}</p>}
          <div className="inputs">
            <input
              type="text"
              value={inputApi}
              onChange={(e) => setInputApi(e.target.value)}
            />
            <button className="btn inverted" disabled={isPending}>
              Login
            </button>
          </div>
        </label>
      </form>
    </div>
  );
}
// https://www.torn.com/preferences.php#tab=api?step=addNewKey&title=Mug%20City&user=timestamp,profile,personalstats,log
