import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions } from "../../firebase/config";
import { usePersistentStorageValue } from "../../hooks/usePersistentStorageValue";
import { TargetDoc } from "./Targets";

export default function TargetLink(props: {
  target: TargetDoc;
  url: string;
  text: string;
  setWasClicked: (wasClicked: boolean) => void;
}) {
  const { target, url, text, setWasClicked } = props;
  const [apiKey] = usePersistentStorageValue("tornApiKey", "");
  const [isPending, setIsPending] = useState(false);

  const handleLinkClick = async () => {
    // Get target's profile data and save to user's database
    setWasClicked(true);
    if (typeof apiKey !== "string") return;
    if (apiKey === "") return;
    if (isPending === true) return;
    if (target.profile) {
      const oneWeek = 1000 * 60 * 60 * 24 * 7;
      const timeDiff = target.profile.timestamp * 1000 - Date.now();
      if (timeDiff < oneWeek) return;
    }
    setIsPending(true);
    console.log("pending", apiKey, target.id);
    const getProfile = httpsCallable<
      { tornApiKey: string; targetId: string },
      unknown
    >(functions, "getProfile");
    await getProfile({ tornApiKey: apiKey, targetId: target.id });
    setIsPending(false);
  };
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      id={target.id}
      onMouseDown={handleLinkClick}
    >
      {text}
    </a>
  );
}
